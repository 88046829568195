import React from 'react';
import { useTranslation } from 'react-i18next';

export interface TimeProps {
  from: Date;
  to?: Date;
}

const Time = ({ to, from }: TimeProps) => {
  const { t } = useTranslation(`Title`);

  if (to) {
    return (
      <time className="">
        {t(`timeSpan`, {
          startDate: from,
          endDate: to,
        })}
      </time>
    );
  }
  return (
    <time className="">
      {t(`time`, {
        startDate: from,
      })}
    </time>
  );
};

export default Time;
