import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';
import Time from './Time';

export interface EmploymentDescription {
  startDate: Date;
  endDate: Date;
  image: string;
  jobTitle: string;
  activities: string[];
}

export interface EmploymentProps {
  employers: Record<string, EmploymentDescription>;
}

const Employment: React.FC<EmploymentProps> = ({ employers }) => {
  const { t } = useTranslation(`Employment`);

  return (
    <Section name="Employment">
      <ol className="relative border-l border-solid border-gray-500 mt-4">
        {Object.keys(employers).map((key) => {
          const employment = employers[key];
          return (
            <li key={key} className="ml-4 mb-4">
              <div className="absolute w-3 h-3 bg-gray-500 rounded-full mt-1.5 -left-1.5 border border-white" />
              <ScrollAnimation animateOnce animateIn="animate__fadeInUp">
                <div className="grid grid-cols-[6em_1fr]">
                  <img className="w-20" src={employment.image} alt={key} />
                  <div>
                    <div className="flex flex-col start-left">
                      <span className="text-lg font-bold mr-4">
                        {t(employment.jobTitle)}
                      </span>
                      <Time
                        from={employment.startDate}
                        to={employment.endDate}
                      />
                    </div>
                    <p className="text-base font-normal">{t(`${key}Desc`)}</p>
                    <ul className="list-disc list-inside">
                      {employment.activities.map((act) => (
                        <li key={act}>{t(act)}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </ScrollAnimation>
            </li>
          );
        })}
      </ol>
    </Section>
  );
};

export default Employment;
