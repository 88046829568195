import { EmploymentDescription } from '@/components/Employment';
import IHMCLogo from './employers/IHMC.png';
import UWFLogo from './employers/UWF.png';
import IKALogo from './employers/IKA.png';
import EGOLogo from './employers/EGO.jpeg';
import EDISONFATHEPOURLogo from './employers/EDISONFATHEPOUR.png';
import TUILogo from './employers/TUI.png';
import FKIELogo from './employers/FKIE.jpeg';
import SOVAMEDLogo from './employers/SOVAMED.png';
import MCCLogo from './employers/MenschCologne.png';
import H2TLogo from './employers/H2T.png';

const EmplomentContent: Record<string, EmploymentDescription> = {
  H2T: {
    startDate: new Date(2022, 10, 1, 0, 0, 0, 0),
    endDate: new Date(2023, 6, 15, 0, 0, 0, 0),
    image: H2TLogo,
    jobTitle: `Phd student`,
    activities: [
      `H2TEtherCatFirmware`,
      `H2TArmar7Robot`,
      `H2TArmar6Teleoperation`,
      `H2TResearch`,
    ],
  },
  MCC: {
    startDate: new Date(2022, 4, 23, 0, 0, 0, 0),
    endDate: new Date(2022, 7, 31, 0, 0, 0, 0),
    image: MCCLogo,
    jobTitle: `Strategy Analyst`,
    activities: [`MCCInterestRateSwapResearch`],
  },
  IHMC: {
    startDate: new Date(2021, 4, 17, 0, 0, 0, 0),
    endDate: new Date(2021, 11, 11, 0, 0, 0, 0),
    image: IHMCLogo,
    jobTitle: `Student researcher`,
    activities: [`IHMCDroneCTFProject`, `IHMCAtlasRLProject`],
  },
  UWF: {
    startDate: new Date(2020, 7, 24, 0, 0, 0, 0),
    endDate: new Date(2022, 4, 7, 0, 0, 0, 0),
    image: UWFLogo,
    jobTitle: `Teaching assistant`,
    activities: [`UWFGrading`],
  },
  EGO: {
    startDate: new Date(2019, 9, 14, 0, 0, 0, 0),
    endDate: new Date(2020, 0, 26, 0, 0, 0, 0),
    image: EGOLogo,
    jobTitle: `Student researcher`,
    activities: [`EGOAutonomousDriving`],
  },
  IKA: {
    startDate: new Date(2018, 2, 15, 0, 0, 0, 0),
    endDate: new Date(2019, 4, 31, 0, 0, 0, 0),
    image: IKALogo,
    jobTitle: `Student researcher`,
    activities: [`IKAWebsite`, `IKAV2XCommunication`],
  },
  TUI: {
    startDate: new Date(2016, 3, 23, 0, 0, 0, 0),
    endDate: new Date(2016, 8, 30, 0, 0, 0, 0),
    image: TUILogo,
    jobTitle: `SportEntertainer`,
    activities: [`TUIGuestCare`, `TUIShows`, `TUIKidsCare`],
  },
  EDISONFATHEPOUR: {
    startDate: new Date(2016, 1, 22, 0, 0, 0, 0),
    endDate: new Date(2016, 3, 5, 0, 0, 0, 0),
    image: EDISONFATHEPOURLogo,
    jobTitle: `Intern`,
    activities: [`EDISONFATHEPOURPreStudyInternship`],
  },
  FKIE: {
    startDate: new Date(2013, 3, 1, 0, 0, 0, 0),
    endDate: new Date(2013, 3, 12, 0, 0, 0, 0),
    image: FKIELogo,
    jobTitle: `Student researcher`,
    activities: [`FKIEPorter`],
  },
  SOVAMED: {
    startDate: new Date(2011, 6, 27, 0, 0, 0, 0),
    endDate: new Date(2012, 2, 8, 0, 0, 0, 0),
    image: SOVAMEDLogo,
    jobTitle: `Developer`,
    activities: [`SOVAMEDDevelopment`, `SOVAMEDDocuments`],
  },
};

export default EmplomentContent;
