import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Section from './Section';

import AIIcon from '../content/freelance/artificial-intelligence.svg';
import CloudIcon from '../content/freelance/cloud.svg';
import NeuralIcon from '../content/freelance/neural.svg';
import RemoteIcon from '../content/freelance/remote-control.svg';
import RenewableEnergyIcon from '../content/freelance/renewable-energy.svg';
import TouchscreenIcon from '../content/freelance/touchscreen.svg';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

interface CardProps {
  desc: string[];
  icon: string;
  delay: number;
}

const Card: React.FC<CardProps> = ({ desc, icon, delay }) => (
  <ScrollAnimation
    animateIn="animate__fadeIn"
    animateOnce
    className="basis-full md:basis-1/2 lg:basis-1/3 p-4"
    delay={delay}
  >
    <div className="flex flex-col justify-center items-center">
      <img className="w-24 h-24 mb-4" src={icon} alt="Icon" />
      {desc.map((d) => (
        <p className="text-lg text-center">{d}</p>
      ))}
    </div>
  </ScrollAnimation>
);

const Freelance: React.FC = () => {
  const { t } = useTranslation(`Freelance`);

  return (
    <Section name="Freelance">
      <ScrollAnimation
        animateOnce
        initiallyVisible
        animateIn="animate__fadeIn"
        className="basis-full md:basis-1/2 lg:basis-1/3 p-4"
      >
        <p className="text-lg mb-4">{t(`Desc1`)}</p>
        <p className="text-lg mb-4">{t(`Desc2`)}</p>
        <p className="text-lg mb-4">{t(`Desc3`)}</p>
      </ScrollAnimation>
      <div className="flex flex-row flex-wrap">
        <Card delay={0} desc={[t(`touchscreenDesc`)]} icon={TouchscreenIcon} />
        <Card
          delay={100}
          desc={[t(`networkingDesc1`), t(`networkingDesc2`)]}
          icon={NeuralIcon}
        />
        <Card
          delay={200}
          desc={[t(`energyDesc1`), t(`energyDesc2`), t(`energyDesc3`)]}
          icon={RenewableEnergyIcon}
        />
        <Card delay={300} desc={[t(`aiDesc`)]} icon={AIIcon} />
        <Card delay={400} desc={[t(`remoteDesc`)]} icon={RemoteIcon} />
        <Card delay={500} desc={[t(`cloudDesc`)]} icon={CloudIcon} />
      </div>
    </Section>
  );
};

export default Freelance;
